import React from 'react';
import './App.css';
import logo from './logo.png'; // Import the logo image

function App() {
  return (
    <div className="App">
	<header className="App-header">
	            <img src={logo} alt="Logo" className="App-logo" /> {/* Add the logo */}

        <h1>Bike Folds Three</h1>
        <p>Funny haha stuff for the funny haha set</p>
        <div className="social-links">
	    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
		<i className="fa-brands fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
		<i className="fa-brands fa-tiktok"></i> {/* TikTok doesn't have a direct Font Awesome icon, using music note as a placeholder */}
            </a>
	    {/*            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
		<i className="fa-brands fa-twitter"></i>
		</a>*/}
            <a href="mailto:bikefoldsthree@gmail.com" target="_blank" rel="noopener noreferrer">
		<i className="fa fa-envelope"></i>
            </a>
        </div>
      </header>
    </div>
  );
}

export default App;
